





































import { Component, Vue } from "vue-property-decorator";
import grupo_dashboardMmodule from "@/store/modules/grupo_dashboard-module";
import { RouterNames } from "@/router/routernames";
@Component({
  components: {},
})
export default class selectsheet extends Vue {
  created() {
    if (grupo_dashboardMmodule.grupo_dashboards.length <= 0) {
      grupo_dashboardMmodule.getgrupo_dashboards_public();
    }
  }
  get allbuttons() {
    return grupo_dashboardMmodule.grupo_dashboards;
  }
  public goto(id_group: number) {
    this.$router.push({
      name: RouterNames.Dash_sin_login,
      params: { id: id_group.toString() },
    });
  }
}
